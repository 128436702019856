// ridimensionamento del box-sizing più intuitivo.
*, *::before, *::after {
    box-sizing: border-box;
  }
  // rimozione del margine di default
  * {
    margin: 0;
    padding: 0;
  }
  // scrollBar
  html{
    overflow-y: scroll !important;
  }
  body{
    overflow-x: hidden !important;
  }
  // scroll
  html:focus-within {
    scroll-behavior: smooth;
  }
  // modifiche tipografiche
  body {
    line-height: 1.5;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
  }
  // rimozione degli stili di default dei form
  input, button, textarea, select {
    font: inherit;
  }
  // ripristina la proprietà 'white-space' per gli elementi textarea su safari
  textarea {
    white-space: revert;
  }
  // rimozione stile di default delle liste
  ol, ul {
    list-style: none;
  }
  // rimozione della spaziatura tra le celle nelle tabelle
  table {
    border-collapse: collapse;
  }
  // evitare Overflow del testo
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }