@mixin h1{
    font-size: $size_1000;
    font-weight: $weight_1000;
    line-height: $lh_1000;
    font-family: $fire_sans;
    @media(max-width: 770px){
        font-size: $size_800;
        line-height: $lh_800;
    }
}

@mixin h2{
    font-size: $size_900;
    font-weight: $weight_900;
    line-height: $lh_900;
    font-family: $fire_sans;
    color: $primary3;
    @media(max-width: 770px){
        font-size: $size_700;
        line-height: $lh_800;
    }
}

@mixin h3{
    font-size: $size_800;
    font-weight: $weight_900;
    line-height: $lh_800;
    font-family: $fire_sans;
}

@mixin h4{
    font-size: $size_600;
    font-weight: $weight_700;
    line-height: $lh_700;
    font-family: $fire_sans;
}

@mixin h5{
    font-size: $size_600;
    font-weight: $weight_700;
    line-height: $lh_600;
    font-family: $fire_sans;
}

@mixin h6{
    font-size: $size_500;
    font-weight: $weight_700;
    line-height: $lh_500;
    font-family: $fire_sans;
}

@mixin p{
    font-size: $size_300;
    font-weight: $weight_300;
    line-height: $lh_500;
    font-family: $open_sans;
}

@mixin a-footer{
    font-size: $size_200;
    font-weight: $weight_300;
    line-height: $lh_200;
    color: $primary3;
    text-decoration: none !important;
}

@mixin gradient{
    background: linear-gradient(270deg, #9FD04F 0%, #124506 100%) !important;
}
