// * palette
$white: #FFFFFF;
$black: #000000;
$primary1: #FFF7E3;
$primary2: #AA9B90;
$primary3: #604D3F;
$secondary1: #DBE9B3;
$secondary2: #B5E374;
$secondary3: #124506;
$accent1: #D9FF9C;
$accent2: #8CE961;
$accent3: #058746;
$grey1: #ADB5BD;
$grey2: #898E94;
$grey3: #414345;
// * font-family
$open_sans: 'Open Sans', sans-serif;
$fire_sans: 'Fira Sans', sans-serif;
$size_100: 13px;
$size_200: 15px;
$size_300: 16px;
$size_400: 19px;
$size_500: 20px;
$size_600: 24px;
$size_700: 32px;
$size_800: 42px;
$size_900: 56px;
$size_1000: 80px;

$lh_100: 18px;
$lh_200: 21px;
$lh_300: 22px;
$lh_400: 27px;
$lh_500: 22px;
$lh_600: 26px;
$lh_700: 35px;
$lh_800: 46px;
$lh_900: 61px;
$lh_1000: 88px;

$weight_100: 400;
$weight_200: 400;
$weight_300: 400;
$weight_400: 600;
$weight_500: 600;
$weight_600: 600;
$weight_700: 600;
$weight_800: 700;
$weight_900: 700;
$weight_1000: 900;
