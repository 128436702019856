body {
    background: $primary1;

    a[href="#footer"]{
        text-decoration: none !important;
    }

    h1 {
        @include h1;
    }

    h2 {
        @include h2;
    }

    h3 {
        @include h3
    }

    h4 {
        @include h4
    }

    h5 {
        @include h5
    }

    h6 {
        @include h6
    }

    p {
        @include p
    }

    .logo {
        max-width: 100%;
    }

    @media (max-width: 700px) {
        .logo {
            width: 170px;
            height: 91px;
        }
    }

    .button-CtA-header {
        width: 160px;
        height: 54px;
        @include gradient;
        border: none;
        border-radius: 4px;
        color: #ffffff;

        span {
            width: 84px;
            height: 22px;
            font-size: $size_300;
            font-weight: $weight_800;
            font-family: $open_sans;
            line-height: $lh_200;
        }
    }

    .hero {
        position: relative;
        margin-bottom: 100px;

        span {
            @include h1;
            color: $primary3;
        }

        h1 {
            color: $secondary3;
        }

        p {
            font-weight: $weight_600;
            font-size: $size_400;
            font-family: $open_sans;
            line-height: $lh_300;
            color: $primary3;
        }

        .recycle {
            width: 145px;
            height: 145px;
            transition: 1s;
        }

        .recycle:hover {
            transform: rotate(360deg);
        }

        .p-svg {
            position: relative;
            font-weight: $weight_600;
            font-size: $size_400;
            font-family: $open_sans;
            line-height: $lh_300;
            color: $secondary3;
            top: 1;
        }

    }

    .imgHero {
        max-width: 100%;
    }

    .first-section {
        width: 100%;
        height: inherit;
        background: $secondary1;
        padding-bottom: 130px;

        .swiper-slide{
            padding: 20px;
        }

        .swiper-pagination-bullet-active{
            background-color: $primary3;
        }

        .brown-icon {
            background-color: $primary3;
            border-radius: 50%;
            margin-bottom: 16px;
            // width: 102px;
            // height: 102px;
            padding: 30px;
            display: inline-block;
        }

        h2 {
            margin-top: 30px;
            margin-bottom: 90px;
        }

        p {
            color: $grey3
        }

        // MEDIA QUERY SMALL
        @media(max-width: 770px) {
            h2 {
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }

    }

    .servizzi {
        background: $secondary1;
        padding-bottom: 240px;

        h2 {
            color: $primary3;
            font-size: $size_800;
        }

        .row-servizio {
            padding: 50px 0;
            align-items: flex-end;
        }

        .servizio {
            padding: 32px 64px 32px 32px;
            background-color: $white;
            border-radius: 0px 0px 24px 0px;
            width: 746;
            height: fit-content;
            position: relative;

            &:after {
                position: absolute;
                content: '';
                background: url('../src/images/svg/Vector.svg') bottom right no-repeat;
                width: 142px;
                height: 294px;
                display: block;
                right: -65px;
                bottom: 0;
                z-index: 1;
            }

            img{
                margin-top: 20px;
            }

            p{
                width: 85%;
            }
        }

        img {
            width: 100%;
            height: auto;
            max-height: 378px;
        }
    }

    .second-section {
        background: linear-gradient(to bottom, $secondary1 50%, $primary1 50%);
        position: relative;

        h2 {
            color: white;
        }

        .intro{
            margin-bottom: 40px;
        }

        p {
            color: $primary1;
            font-size: $size_600;
            margin: 24px 0;
            padding: 0 130px;
        }

        p:last-child {
            margin-bottom: 100px;
        }

        @media (max-width: 767px){
            p{
                padding: 0 50px;
            }
        }

        .brown-box {
            position: relative;
            background-color: $primary3;
        }

        .brown-box::before {
            position: absolute;
            content: '';
            width: 92%;
            height: 118px;
            background: linear-gradient(to left, $primary3, $primary2);
            display: inline-block;
            right: 0;
            top: -118px;
            clip-path: polygon(0 100%, 100% 100%, 4% 0);
        }

        .brown-box::after {
            position: absolute;
            content: '';
            width: 92%;
            height: 118px;
            background: linear-gradient(to left, $primary2, $primary3);
            display: inline-block;
            left: 0;
            bottom: -118px;
            clip-path: polygon(96% 100%, 100% 0, 0 0);
        }

        // MEDIA QUERY MEDIUM
        @media(max-width: 900px) {
            .brown-box::before {
                position: absolute;
                content: '';
                width: 92%;
                height: 60px;
                background: linear-gradient(to left, $primary3, $primary2);
                display: inline-block;
                right: 0;
                top: -60px;
                clip-path: polygon(0 100%, 100% 100%, 4% 0);
            }

            .brown-box::after {
                position: absolute;
                content: '';
                width: 92%;
                height: 60px;
                background: linear-gradient(to left, $primary2, $primary3);
                display: inline-block;
                left: 0;
                bottom: -60px;
                clip-path: polygon(96% 100%, 100% 0, 0 0);
            }
        }

        // MEDIA QUERY SMALL
        @media(max-width: 770px) {
            .brown-box::before {
                position: absolute;
                content: '';
                width: 92%;
                height: 50px;
                background: linear-gradient(to left, $primary3, $primary2);
                display: inline-block;
                right: 0;
                top: -49px;
                clip-path: polygon(0 100%, 100% 100%, 4% 0);
            }

            .brown-box::after {
                position: absolute;
                content: '';
                width: 92%;
                height: 50px;
                background: linear-gradient(to left, $primary2, $primary3);
                display: inline-block;
                left: 0;
                bottom: -49px;
                clip-path: polygon(96% 100%, 100% 0, 0 0);
            }

            .hero {
                margin-bottom: 130px;
            }
        }

        .h2-brown-box {
            margin-top: 85px;
            margin-bottom: 24px;
        }

        .card-brown-box {
            background-color: white;
            border-radius: 16px;
            width: 305px;
            height: inherit;
            padding: 20px;
            margin: 1em !important
        }
    }


    .third-section {

        .container-third-section {
            position: relative;
            margin-top: 300px;
            margin-bottom: 164px;

            h2 {
                color: $black;
            }

            #after {
                position: relative;

                .bamboo-img {
                    max-width: 100%;
                    position: relative;
                }

                @media (max-width: 900px) {
                    .bamboo-img {
                        width: 100%;
                        height: 80%;
                    }
                }

                @media (max-width: 700px) {
                    .bamboo-img {
                        width: 100%;
                        height: 80%;
                    }
                }
            }

            #after:after {
                position: absolute;
                display: inline-block;
                top: -85px;
                right: 20px;
                content: "";
                width: 168px;
                height: 170px;
                background: url('../../webpack/src/images/svg/detail.svg');
                z-index: 1;
            }

            @media(max-width: 700px) {
                #after:after {
                    position: absolute;
                    display: inline-block;
                    top: -60px;
                    right: 10px;
                    content: "";
                    width: 103px;
                    height: 104px;
                    background: url('../../webpack/src/images/svg/detail.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: 1;
                }
            }

        }


        .button-third-section {
            padding: 16px 24px;
            border-radius: 4px;
            border: none;
            color: #ffffff;
            font-weight: $weight_800;
            @include gradient;
        }
    }

    .fourth-section {
        position: relative;
        background-color: transparent;

        h2 {
            color: $black;
        }

        .cards-contatti {
            margin-top: 80px;

            .card-fourth-section {
                box-shadow: 2px 5px 7px 1px rgba(26, 26, 26, 0.2);
                height: inherit;
                background: #FFFFFF;
                border-radius: 16px;
                margin: 0.5rem !important;
                padding: 1.5rem !important;
            }

            a {
                text-decoration: none;
                color: $primary3;
            }
        }

    }

    .fourth-section::after {
        z-index: -1;
        position: absolute;
        width: 976px;
        height: 690px;
        left: 524px;
        top: -150px;
        content: '';
        background: url('../../webpack/src/images/svg/carta.png');
        mix-blend-mode: multiply;
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    .footer {
        background: linear-gradient(to bottom, $primary1 20%, $secondary1 80%);
        margin-top: 279px;
        padding-left: 10%;
        padding-right: 10%;

        p,
        p a {
            @include a-footer;
            transition: 1s;
        }

        .link-icon-footer {
            color: transparent !important;
        }

        .logo-footer {
            width: 239px;
            height: 128px;
        }

        hr {
            width: 100%;
            height: 0px;
            border: 1px solid $primary2;
            margin-top: 26px;
            margin-bottom: 16px;
        }

    }


    @media(max-width: 900px) {
        .fourth-section:after {
            z-index: -1;
            position: absolute;
            width: 1000px;
            height: 900px;
            left: -40px;
            top: 330px;
            mix-blend-mode: multiply;
            opacity: 0.5;
        }
    }

    @media(max-width: 700px) {
        .fourth-section:after {
            z-index: -1;
            position: absolute;
            width: 800px;
            height: 700px;
            left: -40px;
            top: 540px;
            mix-blend-mode: multiply;
            opacity: 0.5;
        }

        .footer {
            margin-top: 518px;
        }
    }

    @media (max-width: 576px){
            .first-section{
                padding-bottom: 100px;
            }

            .fourth-section:after {
                z-index: -1;
                position: absolute;
                width: 800px;
                height: 700px;
                left: -40px;
                top: 1140px;
                mix-blend-mode: multiply;
                opacity: 0.5;
            }

            .footer {
                margin-top: 650px;
            }
    }

}

.flag-en,.flag-it{
    color: #604D3F;
    text-decoration: none;
    display: flex;
    align-items: center;
    span{
        font-size: 16px;
        font-weight: 700;
        font-family: "Open Sans", sans-serif;
        line-height: 21px;
    }
}
@media (max-width: 576px){
    .flag-en,.flag-it{
        flex-direction: column;
        justify-content: center;
    }
}